export function isValidEmail(email: string) {
  const [mail, domain, ...rest] = email.trim().split("@")

  const isValidDomain =
    domain &&
    domain.includes(".") &&
    domain.split(".").every(value => value !== "")

  return mail.length > 0 && isValidDomain && rest.length === 0
}
